import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import Layout from '../../components/layout'
import DynamicPage from '../../components/dynamic-page'

const PagePreview = ({ location, pageContext }) => {
  const [content, setContent] = useState({
    title: '',
    description: '',
    html: '',
  })
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const getData = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      const pageId = urlParams.get('id')
      const { data } = await axios.get(
        `${process.env.GATSBY_API_URL}/geneone/wp-json/wp/v2/pages/${pageId}`
      )
      setContent({
        title: data.acf.title,
        description: data.acf.subtitle,
        html: data.content.rendered,
      })
    }
    try {
      getData()
    } catch {
      setIsError(true)
    }
  }, [])

  return (
    <Layout location={location} pageContext={pageContext}>
      {!isError ? (
        <DynamicPage html={content.html} title={content.title} description={content.description} />
      ) : (
        <h1>Falha ao obter preview.</h1>
      )}
    </Layout>
  )
}

PagePreview.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default PagePreview
